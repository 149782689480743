import Contact from "../../components/pages/contact";
import React from "react";
import Seo from "../../components/layout/seo";

export default ({ location }) => (
  <>
    <Seo title="Contact Us" desc="" />
    <Contact location={location} />
  </>
);
